export default {
    "name": "vega-lite",
    "author": "Dominik Moritz, Kanit \"Ham\" Wongsuphasawat, Arvind Satyanarayan, Jeffrey Heer",
    "version": "5.16.1",
    "collaborators": [
        "Kanit Wongsuphasawat (http://kanitw.yellowpigz.com)",
        "Dominik Moritz (https://www.domoritz.de)",
        "Arvind Satyanarayan (https://arvindsatya.com)",
        "Jeffrey Heer (https://jheer.org)"
    ],
    "homepage": "https://vega.github.io/vega-lite/",
    "description": "Vega-Lite is a concise high-level language for interactive visualization.",
    "keywords": [
        "vega",
        "chart",
        "visualization"
    ],
    "main": "build/vega-lite.js",
    "unpkg": "build/vega-lite.min.js",
    "jsdelivr": "build/vega-lite.min.js",
    "module": "build/src/index",
    "types": "build/src/index.d.ts",
    "bin": {
        "vl2pdf": "./bin/vl2pdf",
        "vl2png": "./bin/vl2png",
        "vl2svg": "./bin/vl2svg",
        "vl2vg": "./bin/vl2vg"
    },
    "files": [
        "bin",
        "build",
        "src",
        "vega-lite*",
        "tsconfig.json"
    ],
    "scripts": {
        "changelog": "conventional-changelog -p angular -r 2",
        "prebuild": "yarn clean:build",
        "build": "yarn build:only",
        "build:only": "tsc -p tsconfig.build.json && rollup -c",
        "prebuild:examples": "yarn build:only",
        "build:examples": "yarn data && TZ=America/Los_Angeles scripts/build-examples.sh",
        "prebuild:examples-full": "yarn build:only",
        "build:examples-full": "TZ=America/Los_Angeles scripts/build-examples.sh 1",
        "build:example": "TZ=America/Los_Angeles scripts/build-example.sh",
        "build:toc": "yarn build:jekyll && scripts/generate-toc",
        "build:site": "rollup -c site/rollup.config.mjs",
        "build:jekyll": "pushd site && bundle exec jekyll build -q && popd",
        "build:versions": "scripts/update-version.sh",
        "clean": "yarn clean:build && del-cli 'site/data/*' 'examples/compiled/*.png' && find site/examples ! -name 'index.md' ! -name 'data' -type f -delete",
        "clean:build": "del-cli 'build/*' !build/vega-lite-schema.json",
        "data": "rsync -r node_modules/vega-datasets/data/* site/data",
        "schema": "mkdir -p build && ts-json-schema-generator -f tsconfig.json -p src/index.ts -t TopLevelSpec --no-type-check --no-ref-encode > build/vega-lite-schema.json && yarn renameschema && cp build/vega-lite-schema.json site/_data/",
        "renameschema": "scripts/rename-schema.sh",
        "presite": "yarn data && yarn schema && yarn build:site && yarn build:versions && scripts/create-example-pages.sh",
        "site": "yarn site:only",
        "site:only": "pushd site && bundle exec jekyll serve -I -l && popd",
        "prettierbase": "prettier '**/*.{md,css,yml}'",
        "format": "eslint . --fix && yarn prettierbase --write",
        "lint": "eslint . && yarn prettierbase --check",
        "jest": "NODE_OPTIONS=--experimental-vm-modules npx jest",
        "test": "yarn jest test/ && yarn lint && yarn schema && yarn jest examples/ && yarn test:runtime",
        "test:cover": "yarn jest --collectCoverage test/",
        "test:inspect": "node --inspect-brk --experimental-vm-modules ./node_modules/.bin/jest --runInBand test",
        "test:runtime": "NODE_OPTIONS=--experimental-vm-modules TZ=America/Los_Angeles npx jest test-runtime/ --config test-runtime/jest-config.json",
        "test:runtime:generate": "yarn build:only && del-cli test-runtime/resources && VL_GENERATE_TESTS=true yarn test:runtime",
        "watch": "tsc -p tsconfig.build.json -w",
        "watch:site": "yarn build:site -w",
        "watch:test": "yarn jest --watch test/",
        "watch:test:runtime": "NODE_OPTIONS=--experimental-vm-modules TZ=America/Los_Angeles npx jest --watch test-runtime/ --config test-runtime/jest-config.json",
        "release": "release-it"
    },
    "repository": {
        "type": "git",
        "url": "https://github.com/vega/vega-lite.git"
    },
    "license": "BSD-3-Clause",
    "bugs": {
        "url": "https://github.com/vega/vega-lite/issues"
    },
    "devDependencies": {
        "@babel/core": "^7.22.10",
        "@babel/plugin-proposal-class-properties": "^7.18.6",
        "@babel/preset-env": "^7.22.10",
        "@babel/preset-typescript": "^7.22.5",
        "@release-it/conventional-changelog": "^7.0.0",
        "@rollup/plugin-alias": "^5.0.0",
        "@rollup/plugin-babel": "^6.0.3",
        "@rollup/plugin-commonjs": "^25.0.4",
        "@rollup/plugin-json": "^6.0.0",
        "@rollup/plugin-node-resolve": "^15.2.1",
        "@rollup/plugin-terser": "^0.4.3",
        "@types/chai": "^4.3.5",
        "@types/d3": "^7.4.0",
        "@types/jest": "^29.5.4",
        "@types/pako": "^2.0.0",
        "@typescript-eslint/eslint-plugin": "^6.4.1",
        "@typescript-eslint/parser": "^6.4.1",
        "ajv": "^8.12.0",
        "ajv-formats": "^2.1.1",
        "chai": "^4.3.7",
        "cheerio": "^1.0.0-rc.12",
        "conventional-changelog-cli": "^4.0.0",
        "d3": "^7.8.5",
        "del-cli": "^5.0.0",
        "eslint": "^8.47.0",
        "eslint-config-prettier": "^9.0.0",
        "eslint-plugin-jest": "^27.2.3",
        "eslint-plugin-prettier": "^5.0.0",
        "fast-json-stable-stringify": "~2.1.0",
        "highlight.js": "^11.8.0",
        "jest": "^29.6.3",
        "jest-dev-server": "^9.0.0",
        "mkdirp": "^3.0.1",
        "pako": "^2.1.0",
        "prettier": "^3.0.2",
        "puppeteer": "^15.0.0",
        "release-it": "^16.1.5",
        "rollup": "^3.28.1",
        "rollup-plugin-bundle-size": "^1.0.3",
        "rollup-plugin-sourcemaps": "^0.6.3",
        "serve": "^14.2.1",
        "terser": "^5.19.2",
        "ts-jest": "^29.1.1",
        "ts-json-schema-generator": "^1.3.0",
        "typescript": "~5.2.2",
        "vega-cli": "^5.25.0",
        "vega-datasets": "^2.7.0",
        "vega-embed": "^6.22.2",
        "vega-tooltip": "^0.33.0",
        "yaml-front-matter": "^4.1.1"
    },
    "dependencies": {
        "json-stringify-pretty-compact": "~3.0.0",
        "tslib": "~2.6.2",
        "vega-event-selector": "~3.0.1",
        "vega-expression": "~5.1.0",
        "vega-util": "~1.17.2",
        "yargs": "~17.7.2"
    },
    "peerDependencies": {
        "vega": "^5.24.0"
    },
    "engines": {
        "node": ">=18"
    }
}
;